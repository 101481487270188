<template>
	<div class="comment" v-if="loaded">
		<div class="user-info mb-1">
			<h6 class="m-0"><router-link :to="{ name: 'profile', params: { id: author.id } }" class="profile-link">{{ author.username }}</router-link></h6>
			<p class="text-muted m-0">{{ postedTimeMessage }}</p>
		</div>
		<div class="reaction">
			<div class="float-end">
				<vote-buttons :upvotes="upvotes" :upvoted="upvoted" @addUpvote="upvoteComment()" @removeUpvote="removeUpvote()"></vote-buttons>
			</div>
		</div>
		<div class="comment-text">
			<p>{{ content }}</p>
		</div>
	</div>
</template>

<style lang="scss">
.comment {
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
}
</style>

<script>
export default {
	data() {
		return {
			author: null,
			loaded: false
		};
	},
	async created() {
		await this.resolveAuthor();
		this.loaded = true;
	},
	methods: {
		async resolveAuthor() {
			this.author = await this.$store.dispatch("users/getUserById", this.authorId);
		},
		upvoteComment() {
			this.$store.dispatch("feed/upvoteComment", {
				commentId: this.id,
				postId: this.postId
			});
		},
		removeUpvote() {
			this.$store.dispatch("feed/removeCommentUpvote", {
				commentId: this.id,
				postId: this.postId
			});
		}
	},
	props: {
		id: {
			type: String,
			required: true
		},
		postId: {
			type: String,
			required: true
		},
		authorId: {
			type: String,
			required: true
		},
		content: {
			type: String,
			required: true
		},
		createdAt: {
			type: String,
			required: true
		},
		upvotes: {
			type: Number,
			required: true
		},
		upvoteList: {
			type: Set,
			require: true
		}
	},
	computed: {
		upvoted() {
			return this.upvoteList.has(this.id);
		},
		postedTimeMessage() {
			const createdDate = new Date(this.createdAt);
			const now = new Date();

			const daysPassed = now.getDay() - createdDate.getDay();
			const minutes = createdDate.getMinutes().toString().padStart(2, "0");
			const hour = createdDate.getHours() % 12 || 12;
			const meridiem = createdDate.getHours() < 12 ? "AM" : "PM";

			if(daysPassed === 0) {
				return `Today at ${hour}:${minutes} ${meridiem}`;
			} else if(daysPassed === 1) {
				return `Yesterday at ${hour}:${minutes} ${meridiem}`;
			} else {
				return `${createdDate.getMonth() + 1}/${createdDate.getDay() + 1}/${createdDate.getFullYear()} at ${hour}:${minutes} ${meridiem}`;
			}
		}
	}
};
</script>
